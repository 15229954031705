<template>
  <div id="app">
    <!--    <HeadRoom/>-->
    <Header/>
    <HeaderProgress/>
    <router-view/>
  </div>
</template>

<style>

@import "assets/css/animate.css";
@import "assets/css/bootstrap.min.css";
@import "assets/css/font-awesome.css";
@import "assets/css/jquery.fancybox.min.css";
@import "assets/css/slick.css";
@import "assets/css/styles.css";

</style>

<script>

/*import Home from './view/Home'*/
/*import HeadRoom from './components/Navigation/HeadRoom'*/
import Header from './components/Navigation/Header'
import HeaderProgress from './components/Navigation/HeaderProgress'

export default {
  name: 'App',
  components: {
    /*Home,*/
    Header,
    /*HeadRoom,*/
    HeaderProgress
  }
}
</script>
