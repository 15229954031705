<template>
  <div class="quiz-progress-bar fixed-top">
    <div class="quiz-progress" :style="progressStyle"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage: 0
    }
  },
  computed: {
    progressStyle() {
      return {
        width: `${this.percentage}%`
      }
    }
  },
  mounted() {
    this.$root.$on('update-progress', async (percentage) => {
      this.percentage = percentage
    })
  }
}
</script>
