<template>
  <header :class="{'header-light': theme === 'light', 'header-dark': theme === 'dark' }">
    <div class="container">
      <div class="text-center">
        <a href="javascript:void(0);" class="logo"
           :class="{'logo-light': theme === 'light', 'logo-dark': theme === 'dark' }">LifeMD</a>
      </div>
      <a @click="goBack" class="btn-step-prev"
         :class="{'btn-step-prev-light': theme === 'light', 'btn-step-prev-dark': theme === 'dark' }">←</a>
    </div>
  </header>
</template>

<style>
.header-light {
  background: #fafbfc;
}

.header-dark {
  background: transparent;
}

.logo-light {
  background-image: url('../../assets/img/logo.svg');
}

.logo-dark {
  background-image: url('../../assets/img/logo-white.svg');
}

.btn-step-prev-light {
  background-image: url('../../assets/img/icon-arrow-left.svg');
}

.btn-step-prev-dark {
  background-image: url('../../assets/img/icon-arrow-left-white.svg');
}

</style>

<script>
export default {
  data() {
    return {
      theme: 'light'
    }
  },
  methods: {
    goBack() {
      this.$root.$emit('go-back', null);
    }
  },
  mounted() {
    this.$root.$on('header-update', async (theme) => {
      this.theme = theme;
    })
  }
}
</script>
