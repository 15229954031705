import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import browserDetect from "vue-browser-detect-plugin";
import vWow from 'v-wow'
import VueMask from 'v-mask'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

Vue.use(browserDetect);
Vue.use(vWow);
Vue.use(VueMask);

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
